.Button {
  background: #0094B3;
  border-radius: 0.285rem;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  color: white;
  border: 1px solid transparent;
  transition: background 0.5s ease, opacity 0.3s ease;
  display: inline-block;
}

a.Button {
  text-decoration: none;
}

.Button.full {
  width: 100%;
}

.Button.small {
  padding: 0.5em 1em;
  font-size: 0.8em;
}


/* text */
.Button.text {
  background: transparent;
  color: #969CA4;
  padding: 0.5rem 1rem;
}
.Button.text:hover:not(:disabled) {
  /* color: #969CA4; */
  background: #F4F5F7;
}


/* disabled */
.Button:not(:disabled) {
  cursor: pointer;
}
.Button:disabled {
  background: #F4F5F7;
  color: #DBDDDE;
}

.Button:focus {
  transition: border 0.5s ease;
  outline: none !important;
  border: 1px solid rgba(158, 158, 158, 0.5);
}

/* hover */
.Button:hover:not(.text):not(:disabled) {
  background: #00BBE0;
}

.Button:active {
  background: #016E8D;
}

/* loading */
.Button.loading {
  padding: 0.2em 1em;
}
.Button.small.loading {
  padding: 0 1em;
}
.Button .Button-loader {
  transition: visibility 1.5s ease, opacity 1.5s ease;
  visibility: hidden;
  opacity: 0.1;
  position: absolute;
  left: -999em;
}
.Button.loading .Button-loader {
  opacity: 1;
  visibility: visible;
  position: relative;
  left: auto;
}
.Button.small.loading .Button-loader {
  width: 3.2em;
}
.Button-loader {
  width: 4.2em;
}
