.RotateDeviceWarning {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.RotateDeviceWarning > img {
  margin-bottom: 2rem;
}

.RotateDeviceWarning > h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1.75rem;
  color: #00233B;
}

.RotateDeviceWarning > p {
  margin-top: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  text-align: center;
  color: #596975;
}

@media (orientation: landscape) {
  .RotateDeviceWarning {
    display: none;
  }
}
