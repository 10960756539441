.MHub-Icon-calculator .path1:before {
  content: "\e944";
  color: rgb(1, 1, 1);
}

.MHub-Icon-calculator .path2:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}

.MHub-Icon-calculator .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path7:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path8:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path9:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-calculator .path10:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.MHub-Icon-block_quote:before {
  content: "\e970";
}

.MHub-Icon-ordered_list_ol:before {
  content: "\e993";
}

.MHub-Icon-unordered_list_ul_bullet_point:before {
  content: "\e994";
}

.MHub-Icon-campaign:before {
  content: "\e90c";
}

.MHub-Icon-chevron_left_s:before {
  content: "\e90d";
}

.MHub-Icon-chevron_left:before {
  content: "\e90f";
}

.MHub-Icon-chevron_right_s:before {
  content: "\e919";
}

.MHub-Icon-chevron_right:before {
  content: "\e926";
}

.MHub-Icon-close_cancel_1:before {
  content: "\e949";
}

.MHub-Icon-close_cancel_2:before {
  content: "\e94f";
}

.MHub-Icon-delete:before {
  content: "\e953";
}

.MHub-Icon-help:before {
  content: "\e95a";
}

.MHub-Icon-info:before {
  content: "\e95c";
}

.MHub-Icon-leads:before {
  content: "\e96a";
}

.MHub-Icon-login:before {
  content: "\e972";
}

.MHub-Icon-logout:before {
  content: "\e973";
}

.MHub-Icon-menu_hamburger:before {
  content: "\e986";
}

.MHub-Icon-menu_square:before {
  content: "\e98c";
}

.MHub-Icon-notifications:before {
  content: "\e98d";
}

.MHub-Icon-opportunity:before {
  content: "\e98e";
}

.MHub-Icon-profile:before {
  content: "\e98f";
}

.MHub-Icon-report:before {
  content: "\e990";
}

.MHub-Icon-search:before {
  content: "\e991";
}

.MHub-Icon-settings:before {
  content: "\e992";
}

.MHub-Icon-action_menu:before {
  content: "\e900";
}

.MHub-Icon-activity_log_milestone:before {
  content: "\e901";
}

.MHub-Icon-add_circle:before {
  content: "\e902";
}

.MHub-Icon-add_columns:before {
  content: "\e903";
}

.MHub-Icon-add_lead:before {
  content: "\e904";
}

.MHub-Icon-add_user:before {
  content: "\e905";
}

.MHub-Icon-add:before {
  content: "\e906";
}

.MHub-Icon-admin:before {
  content: "\e907";
}

.MHub-Icon-agent:before {
  content: "\e908";
}

.MHub-Icon-align_left:before {
  content: "\e909";
}

.MHub-Icon-align_middle:before {
  content: "\e90a";
}

.MHub-Icon-align_right:before {
  content: "\e90b";
}

.MHub-Icon-attach:before {
  content: "\e90e";
}

.MHub-Icon-banker:before {
  content: "\e910";
}

.MHub-Icon-block_code_curly:before {
  content: "\e911";
}

.MHub-Icon-bold:before {
  content: "\e912";
}

.MHub-Icon-booking_setup:before {
  content: "\e913";
}

.MHub-Icon-booking:before {
  content: "\e914";
}

.MHub-Icon-business_unit:before {
  content: "\e915";
}

.MHub-Icon-calendar:before {
  content: "\e916";
}

.MHub-Icon-call:before {
  content: "\e917";
}

.MHub-Icon-camera:before {
  content: "\e918";
}

.MHub-Icon-cancel_invitation:before {
  content: "\e91a";
}

.MHub-Icon-chat:before {
  content: "\e91b";
}

.MHub-Icon-close:before {
  content: "\e91c";
}

.MHub-Icon-code_block_1:before {
  content: "\e91d";
}

.MHub-Icon-code_block_2:before {
  content: "\e91e";
}

.MHub-Icon-cold_leads:before {
  content: "\e91f";
}

.MHub-Icon-collapse:before {
  content: "\e920";
}

.MHub-Icon-color_picker:before {
  content: "\e921";
}

.MHub-Icon-contacts:before {
  content: "\e922";
}

.MHub-Icon-copy:before {
  content: "\e923";
}

.MHub-Icon-credit_check:before {
  content: "\e924";
}

.MHub-Icon-customize:before {
  content: "\e925";
}

.MHub-Icon-description:before {
  content: "\e927";
}

.MHub-Icon-details:before {
  content: "\e928";
}

.MHub-Icon-developer:before {
  content: "\e929";
}

.MHub-Icon-documents:before {
  content: "\e92a";
}

.MHub-Icon-download:before {
  content: "\e92b";
}

.MHub-Icon-dropdown_outline:before {
  content: "\e92c";
}

.MHub-Icon-dropdown:before {
  content: "\e92d";
}

.MHub-Icon-edit:before {
  content: "\e92e";
}

.MHub-Icon-email:before {
  content: "\e92f";
}

.MHub-Icon-favorite:before {
  content: "\e930";
}

.MHub-Icon-filter:before {
  content: "\e931";
}

.MHub-Icon-first_page:before {
  content: "\e932";
}

.MHub-Icon-fit_to_screen:before {
  content: "\e933";
}

.MHub-Icon-font_color:before {
  content: "\e934";
}

.MHub-Icon-form:before {
  content: "\e935";
}

.MHub-Icon-go_to:before {
  content: "\e936";
}

.MHub-Icon-go:before {
  content: "\e937";
}

.MHub-Icon-heading_1:before {
  content: "\e938";
}

.MHub-Icon-heading_2:before {
  content: "\e939";
}

.MHub-Icon-heading_3:before {
  content: "\e93a";
}

.MHub-Icon-heading_4:before {
  content: "\e93b";
}

.MHub-Icon-heading_5:before {
  content: "\e93c";
}

.MHub-Icon-heading_6:before {
  content: "\e93d";
}

.MHub-Icon-hide:before {
  content: "\e93f";
}

.MHub-Icon-highlight:before {
  content: "\e940";
}

.MHub-Icon-history:before {
  content: "\e941";
}

.MHub-Icon-home:before {
  content: "\e942";
}

.MHub-Icon-hot_leads:before {
  content: "\e943";
}

.MHub-Icon-italic:before {
  content: "\e945";
}

.MHub-Icon-justify:before {
  content: "\e946";
}

.MHub-Icon-last_page:before {
  content: "\e947";
}

.MHub-Icon-lawyer:before {
  content: "\e948";
}

.MHub-Icon-link:before {
  content: "\e94a";
}

.MHub-Icon-loan_tracker:before {
  content: "\e94c";
}

.MHub-Icon-location:before {
  content: "\e94d";
}

.MHub-Icon-lock:before {
  content: "\e94e";
}

.MHub-Icon-mail_list:before {
  content: "\e950";
}

.MHub-Icon-manage:before {
  content: "\e951";
}

.MHub-Icon-marketplace:before {
  content: "\e952";
}

.MHub-Icon-minimized:before {
  content: "\e954";
}

.MHub-Icon-move:before {
  content: "\e959";
}

.MHub-Icon-nudge:before {
  content: "\e95b";
}

.MHub-Icon-payment:before {
  content: "\e95e";
}

.MHub-Icon-performance:before {
  content: "\e95f";
}

.MHub-Icon-post_sales:before {
  content: "\e960";
}

.MHub-Icon-preview:before {
  content: "\e961";
}

.MHub-Icon-print:before {
  content: "\e962";
}

.MHub-Icon-project:before {
  content: "\e963";
}

.MHub-Icon-re_Invite:before {
  content: "\e964";
}

.MHub-Icon-reassign:before {
  content: "\e965";
}

.MHub-Icon-redo:before {
  content: "\e966";
}

.MHub-Icon-reload:before {
  content: "\e967";
}

.MHub-Icon-remark:before {
  content: "\e968";
}

.MHub-Icon-remove:before {
  content: "\e969";
}

.MHub-Icon-reveal:before {
  content: "\e96b";
}

.MHub-Icon-rotate_anti_clockwise:before {
  content: "\e96c";
}

.MHub-Icon-rotate:before {
  content: "\e96d";
}

.MHub-Icon-save:before {
  content: "\e96e";
}

.MHub-Icon-schedule:before {
  content: "\e96f";
}

.MHub-Icon-send:before {
  content: "\e971";
}

.MHub-Icon-share:before {
  content: "\e974";
}

.MHub-Icon-showroom:before {
  content: "\e975";
}

.MHub-Icon-sms:before {
  content: "\e976";
}

.MHub-Icon-sort:before {
  content: "\e977";
}

.MHub-Icon-spa:before {
  content: "\e978";
}

.MHub-Icon-staff:before {
  content: "\e979";
}

.MHub-Icon-status:before {
  content: "\e97a";
}

.MHub-Icon-strikethrough:before {
  content: "\e97b";
}

.MHub-Icon-symbol:before {
  content: "\e97c";
}

.MHub-Icon-tags:before {
  content: "\e97d";
}

.MHub-Icon-tick:before {
  content: "\e97e";
}

.MHub-Icon-time:before {
  content: "\e97f";
}

.MHub-Icon-underline:before {
  content: "\e980";
}

.MHub-Icon-undo:before {
  content: "\e981";
}

.MHub-Icon-unit_setup:before {
  content: "\e982";
}

.MHub-Icon-update_lead_age:before {
  content: "\e983";
}

.MHub-Icon-upgrade:before {
  content: "\e984";
}

.MHub-Icon-upload:before {
  content: "\e985";
}

.MHub-Icon-view_sales_chart:before {
  content: "\e987";
}

.MHub-Icon-view:before {
  content: "\e988";
}

.MHub-Icon-warm_leads:before {
  content: "\e989";
}

.MHub-Icon-zoom_in:before {
  content: "\e98a";
}

.MHub-Icon-zoom_out:before {
  content: "\e98b";
}

/* v1.9 */
/* Last generated at Thu, 01 Aug 2019 10:32:04 GMT */