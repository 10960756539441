.FilterDetails {
  position: absolute;
  top: 2.5em;
  left: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  flex-wrap: wrap;
}

.FilterDetails.active {
  opacity: 1;
}
.FilterDetails.active > * {
  pointer-events: initial;
}

.FilterDetails > * {
  margin-top: 0.5em;
  margin-right: 1em;
  border-radius: 0.285rem;
}

.FilterDetails > .total {
  pointer-events: none;
  background: #F8F9F9;
  padding: 0.75em 1em;
  color: #00233B;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.FilterDetails > .total > span {
  margin-left: 0.5em;
  font-weight: normal;
}

.FilterDetails:not(.floating) {
  top: 3.5em;
}
