.AppMenuButton {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;
  outline: none;
  border: none;
  border-radius: 1rem;
  background: transparent;
  justify-content: center;
  transition: all 0.3s;
}
.AppMenuButton:not(.basic) {
  padding: 0.5rem;
  background: white;
  min-width: 1rem;
  min-height: 1rem;
}
.AppMenuButton:not(.basic):hover {
  background: #F4F5F7;
}
