body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

button {
  font-family: "Open Sans", sans-serif;
}

@media only screen and (max-width: 320px) {
  .small-center {
    text-align: center;
  }
}

body *,
body [class^="MHub-"] {
  font-family: 'Open Sans', sans-serif;
}

body #MHub-Portals,
body .MHub-Dropdown-List.withPortal{
  z-index: 7000;
}
body .MHub-Dropdown-List {
  background: white;
  border: 1px solid #DBDDDE;
  border-radius: 0.285rem;
  box-shadow: none;
}

body .MHub-Dropdown-Placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #00233B;
}
body .MHub-Dropdown-List.attached-top {
  border-top-left-radius: 0.285rem !important;
  border-top-right-radius: 0.285rem !important;
}
body .MHub-Dropdown-List > .MHub-Dropdown-Item {
  padding: 0.5rem 0.75rem;
  font-family: 'Open Sans', sans-serif;
  color: #00233B;
}
body .MHub-Dropdown-List > .MHub-Dropdown-Item.selected,
body .MHub-Dropdown-List > .MHub-Dropdown-Item:hover:not(.disabled) {
  background: #F4F5F7;
}

body .MHub-InputWrapper.MHub-Dropdown {
  padding-top: calc(0.75rem - 1px);
  padding-bottom: calc(0.75rem - 1px);
}
body .MHub-InputWrapper:hover:not(.disabled):not(.readOnly),
body .MHub-InputWrapper.focus {
  /* NOTE: fix for IE */
  background: white;
  border-color: #49B3B1;
}
body .MHub-Dropdown-Arrow.active {
  border-top-color: #49B3B1;
}
body .MHub-Label > .header {
  color: #00233B;
  font-size: 0.85em;
}
body .MHub-Label:hover > .header,
body .MHub-Label.focus > .header {
  color: inherit;
}
body .MHub-Label:not(.inline) > .header + .children {
  margin-top: 0.5rem;
}


/* Chrome */
body *::-webkit-scrollbar-thumb {
  border: 1px solid white;
  border-radius: 1rem;
  background: rgb(0, 35, 59, 0.4);
}
body *::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 35, 59, 0.4);
}
body *::-webkit-scrollbar-thumb:active {
  background: rgb(0, 35, 59, 0.4);
}
body *::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.25);
}
body *::-webkit-scrollbar-track:hover {
  background: rgba(255, 255, 255, 0.5);
}
body *::-webkit-scrollbar-track:active {
  background: rgba(255, 255, 255, 0.5);
}
