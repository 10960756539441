.AppMenu {
  position: fixed;
  top: 0;
  right: 100%;
  left: 0;
  height: 100%;
  min-width: 25%;
  background: white;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.3);
  opacity: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
}

/* header */
.AppMenu > .header {
  padding: 1rem;
  height: 3.5rem;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* title */
.AppMenu > .header .title {
  color: #00233B;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.AppMenu > .header .title h4 {
  font-size: 1em;
}

/* content */
.AppMenu > .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #F4F5F7;
  overflow: auto;
  padding: 1rem;
}
.AppMenu > .content > * {
  flex-shrink: 0;
}
.AppMenu > .content > a,
.AppMenu > .content > button {
  margin: 0.25rem 0;
  padding: 0.625rem 1rem;
  float: left;
  color: #00233B;
  background: transparent;
  transition: all 0.3s;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
  box-sizing: content-box;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  text-overflow: ellipsis;
  border-radius: 0.285rem;
}
.AppMenu > .content > a:hover,
.AppMenu > .content > button:hover {
  background: #E7EAED;
}

.AppMenu > .content.projects > a,
.AppMenu > .content.projects > button {
  color: #596975;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}
.AppMenu > .content.projects > a:hover,
.AppMenu > .content.projects > button:hover {
  color: #00233B;
}

/* footer */
.AppMenu > .footer {
  padding: 0.5rem 1rem;
  background: #E7EAED;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
}
.AppMenu > .footer > .row{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.AppMenu > .footer .version {
  color: #969CA4;
  user-select: none;
  text-align: center;
  font-size: 0.75em;
}
.AppMenu > .footer button.logout {
  margin-left: 1rem;
  padding: 0.5rem;
  color: #E7EAED;
  background: transparent;
  transition: all 0.3s;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  align-items: center;
  box-sizing: content-box;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}
.AppMenu > .footer button.logout:hover {
  color: #00233B;
  background: #ccc;
}

/* after open */
.ReactModal__Content--after-open .AppMenu {
  right: 0;
  opacity: 1;
}
@media only screen and (min-width: 48em) {
  .ReactModal__Content--after-open .AppMenu {
    right: 50%;
  }
}
@media only screen and (min-width: 64em) {
  .ReactModal__Content--after-open .AppMenu {
    right: 60%;
  }
}
@media only screen and (min-width: 75em) {
  .ReactModal__Content--after-open .AppMenu {
    right: 70%;
  }
}

/* before close */
.ReactModal__Content--before-close .AppMenu {
  right: 100%;
}

/* profile */
.AppMenu .profile {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: #596975;
}
.AppMenu .profile img {
  margin-right: 0.5rem;
}
.AppMenu .profile .name > h4 {
  font-size: 0.9em;
  font-weight: bold;
  color: #00233B;
  line-height: 1em;
}
.AppMenu .profile .name > .role {
  font-size: 0.75em;
}
