.LoadingPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.LoadingPage .loader {
  width: 10em;
  /* top: 50%;
  position: absolute;
  transform-origin: center;
  transform: translateX(-50%); */
}
