.UnitDetail {
  position: fixed;
  top: 0;
  left: 100%;
  right: 0;
  height: 100%;
  background: white;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.3);
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
}

/* header */
.UnitDetail > .header {
  padding: 1rem;
  height: 3.5rem;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBDDDE;
}
.UnitDetail > .header h4 {
  color: #00233B;
  font-size: 1.1em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.UnitDetail > .header .placeholder {
  padding: 0.5rem;
  min-width: 1rem;
  min-height: 1rem;
}

/* status */
.UnitDetail .status {
  float: left;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: 2rem;
  color: #00233B;
  background: #F8F9F9;;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  align-items: center;
}
.UnitDetail .status > .box {
  width: 1rem;
  height: 1rem;
  background: #00233B;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.UnitDetail .status.new > .box {
  background: #8FD184;
}
.UnitDetail .status.reserved > .box {
  background: #FFDC26;
}
.UnitDetail .status.booked > .box {
  background: #FFB000;
}
.UnitDetail .status.sold > .box {
  background: #FF796E;;
}
.UnitDetail .status.delisted > .box {
  background: rgb(118,118,118);
}


/* footer */
.UnitDetail > .footer {
  border-top: 1px solid #DBDDDE;
  padding: 1rem;
  background: white;
  align-items: center;
  display: flex;
  z-index: 1;
  justify-content: flex-end;
  flex-direction: row;
}

/* content */
.UnitDetail > .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  flex: 1;
  overflow: auto;
}
.UnitDetail > .content > * {
  /* NOTE: safari fix */
  flex-shrink: 0;
}
.UnitDetail > .content.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.UnitDetail > .content:after {
  content: "";
  height: 5rem;
  display: block;
}
/* specifications */
.UnitDetail > .content > .specifications {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E6E6E6;
  flex-wrap: wrap;
}

/* floor plan */
.UnitDetail > .content > .floorplan {
  border-bottom: none;
}

.UnitDetail > .content button.preview {
  text-align: left;
  background: white;
  border-radius: 0.285rem;
  outline: none;
  border: none;
  padding: 0.25rem 0;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  color: #49B3B1;
  display: inline-flex;
  flex-direction: row;
  transition: all 0.3s;
  align-items: center;
  cursor: pointer;
}
.UnitDetail > .content button.preview > img {
  margin-left: 0.5rem;
}
.UnitDetail > .content button.preview:hover {
  color: #32C8C5;
  /* background: #F4F5F7; */
}

/* pictures */
.UnitDetail > .content .pictures {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.UnitDetail > .content .pictures > .LoadImage {
  box-shadow: 0 0.285rem 0.7rem rgba(0, 0, 0, 0.15);
  border-radius: 0.285rem;
  padding: 0.25rem;
  width: auto;
  max-width: 12rem;
  height: auto;
  overflow: hidden;
  background: white;
}

/* after open */
.ReactModal__Content--after-open .UnitDetail {
  left: 0;
  opacity: 1;
}
@media only screen and (min-width: 48em) {
  .ReactModal__Content--after-open .UnitDetail {
    left: 45%;
  }
}
@media only screen and (min-width: 64em) {
  .ReactModal__Content--after-open .UnitDetail {
    left: 60%;
  }
}
@media only screen and (min-width: 75em) {
  .ReactModal__Content--after-open .UnitDetail {
    left: 70%;
  }
}

/* before close */
.ReactModal__Content--before-close .UnitDetail {
  left: 100%;
  transition: all 0.2s;
}

.DetailField.layoutType > .content{
  display: inline-block;
}

.DetailField.layoutType > .content > a{
  margin-left: 1em;
}

.DetailField.layoutType > .content > a > button{
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

.DetailField.layoutType > .content > .unitLayoutNameOnly > a{
  margin-left: 1em;
}

.DetailField.layoutType > .content > .unitLayoutNameOnly > a > button{
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}
