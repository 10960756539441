.ToggleButton {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 0.9em;
}

.ToggleButton > input {
  display: none;
}

.ToggleButton > .slider {
  margin-right: 0.5rem;
  position: relative;
  width: 2rem;
  height: 1rem;
  background: white;
  border-radius: 1rem;
  border: 1px solid #CACBCC;
  transition: all 0.3s;
}

.ToggleButton > .slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: white;
  box-shadow: 0 0.15rem 0.3rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  transition: all 0.3s;
}

.ToggleButton.active > .slider {
  background: #50B793;
  border-color: #50B793;
}
.ToggleButton.active > .slider::before {
  left: calc(100% - 1rem);
}

.ToggleButton.disabled {
  cursor: none;
}

.ToggleButton.disabled > .slider {
  background: #F4F5F7;
  border-color: #F4F5F7;
}
