/* button */
.FilterButton {
  padding: 0.25rem 1rem 0.25rem 0.5rem !important;
  transition: all 0.3s;
  text-decoration: none;
  align-items: center;
  box-sizing: content-box;
  border: none;
  outline: none;
  overflow: hidden;
  border-radius: 1rem;
  line-height: 0.8rem;
}
.FilterButton.active::before {
  content: '';
  position: absolute;
  top: 0.25rem;
  right: 0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #3597EC;
}
