.ImageViewer {
  position: fixed;
  top: 0;
  left: 100%;
  right: 0;
  height: 100%;
  background: white;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.3);
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #00233B;
  font-weight: normal;
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
}

/* header */
.ImageViewer > .header {
  padding: 1rem;
  height: 3.5rem;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #F4F5F7;
  justify-content: space-between;
}
.ImageViewer > .header h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
}
.ImageViewer > .header .placeholder {
  padding: 0.5rem;
  min-width: 1rem;
  min-height: 1rem;
}

/* after open */
.ReactModal__Content--after-open .ImageViewer {
  left: 0;
}

/* before close */
.ReactModal__Content--before-close .ImageViewer {
  left: 100%;
  transition: all 0.2s;
}

/* content */
.ImageViewer > .content {
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* layout */
.ImageViewer > .content > .layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.ImageViewer > .content > .layout > .title {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}
.ImageViewer > .content > .layout > .specifications > div {
  float: left;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.ImageViewer > .content > .layout > .specifications > div:first-child {
  padding-left: 0;
}
.ImageViewer > .content > .layout > .specifications > div:last-child {
  padding-right: 0;
}
.ImageViewer > .content > .layout > .specifications > div img.icon{
  margin-left: 0.5rem;
  /* height: 100%; */
}
.ImageViewer > .content > .layout > *:not(:first-child) {
  margin-left: 1rem;
}

/* picture */
.ImageViewer > .content > .picture {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.ImageViewer > .content > .picture > .label {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1em;
  padding: 1rem;
  padding-top: 0.5rem;
}

/* navigation */
.ImageViewer > .content > .navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ImageViewer > .content > .navigation > .dots {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ImageViewer > .content > .navigation > .dots > .dot {
  padding: 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
}
.ImageViewer > .content > .navigation > .dots > .dot > div{
  width: 0.5rem;
  height: 0.5rem;
  background: #ccc;
  border-radius: 50%;
  transition: all 0.1s;
}

.ImageViewer > .content > .navigation > .dots > .dot.selected > div,
.ImageViewer > .content > .navigation > .dots > .dot:hover > div {
  background: #707070;
}

.AppMenuButton.viewer-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.3);
}
