.AppHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  user-select: none;
  flex-direction: row;
  padding: 1rem;
  height: 3.5rem;
  box-sizing: border-box;
  color: #00233B;
  background: white;
  border-bottom: 1px solid #F4F5F7;
  transition: all 0.3s;
  z-index: 1000;
}

/* title */
.AppHeader > .row > h4.title {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}
.App:not(.hideHeader) .AppHeader > .row > h4.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* row */
.AppHeader > .row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.AppHeader.flutter > .row {
  justify-content: center;
}

.App.hideHeader .AppHeader {
  background: transparent;
  border-bottom: none;
  user-select: none;
  pointer-events: none;
}
.App.hideHeader .AppHeader > .row > h4.title {
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.5);
}
