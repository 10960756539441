/* size */
.LoadImage.small {
  width: 100px;
  height: 100px;
}
.LoadImage.medium {
  width: 300px;
  height: 300px;
}
.LoadImage.large {
  width: 700px;
  height: 700px;
}
.LoadImage.fluid  {
  width: 100%;
  height: 100%;
  flex: 1;
}
