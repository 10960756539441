.Controls {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  user-select: none;
  pointer-events: none;
}

/* hover */
.Controls > * {
  position: relative;
  border-radius: 1rem;
  background: white;
  color: #596975;
  padding: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  cursor: pointer;
  pointer-events: initial;
  margin-left: 1rem;
}
.Controls > *:hover {
  background: #F4F5F7;
}

/* floating */
.Controls.floating {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.Controls.floating > * {
  box-shadow: 0px 0.3rem 1rem rgba(0, 0, 0, 0.1);
}

/* ----------------- */

.FilterMenu {
  position: fixed;
  top: 0;
  left: 100%;
  right: 0;
  height: 100%;
  min-width: 25%;
  background: white;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.3);
  opacity: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
}

/* header */
.FilterMenu > .header {
  padding: 1rem;
  height: 3.5rem;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBDDDE;
}

/* title */
.FilterMenu > .header .placeholder {
  padding: 0.5rem;
  min-width: 1rem;
  min-height: 1rem;
}
.FilterMenu > .header .title {
  color: #00233B;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.FilterMenu > .header .title h4 {
  font-size: 1em;
}

/* content */
.FilterMenu > .content {
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1rem;
}

.FilterMenu > .content .MHub-Label {
  flex: none;
}

/* footer */
.FilterMenu > .footer {
  padding: 1rem;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* after open */
.ReactModal__Content--after-open .FilterMenu {
  left: 0;
  opacity: 1;
}
@media only screen and (min-width: 48em) {
  .ReactModal__Content--after-open .FilterMenu {
    left: 50%;
  }
}
@media only screen and (min-width: 64em) {
  .ReactModal__Content--after-open .FilterMenu {
    left: 60%;
  }
}
@media only screen and (min-width: 75em) {
  .ReactModal__Content--after-open .FilterMenu {
    left: 70%;
  }
}

/* before close */
.ReactModal__Content--before-close .FilterMenu {
  left: 100%;
}
