.modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  outline: none;
  padding: 0;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.3);
  /* background-color: white; */
  z-index: 4999;
  transition: all 0.2s;
}

.modal.side {
  box-shadow: none;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 48em) {
  .modal.side.right {
    left: 50%;
  }
  .modal.side.left {
    right: 50%;
  }
}
@media only screen and (min-width: 64em) {
  .modal.side.right {
    left: 60%;
  }
  .modal.side.left {
    right: 60%;
  }
}
@media only screen and (min-width: 75em) {
  .modal.side.right {
    left: 70%;
  }
  .modal.side.left {
    right: 70%;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 5001;
}

.ReactModal__Overlay {
  z-index: 5000;
  opacity: 0;
  transition: opacity 0.3s;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

/* // before ios 10 */
@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
  body.ReactModal__Body--open #root {
    overflow: hidden;
  }
  body.ReactModal__Body--open #root.small {
    overflow: initial !important;
  }
}
/* // ios 10 */
@supports (-webkit-overflow-scrolling:touch) and (color:#ffff) {
  body.ReactModal__Body--open #root {
    overflow: hidden;
  }
  body.ReactModal__Body--open #root.small {
    overflow: initial !important;
  }
}
