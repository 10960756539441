li.MHub-Dropdown-Item.bumi::before,
li.MHub-Dropdown-Item.tenanted::before,
li.MHub-Dropdown-Item.rent_to_own::before,
.CategoryFilter.bumi .MHub-Dropdown-Placeholder::before,
.CategoryFilter.tenanted .MHub-Dropdown-Placeholder::before,
.CategoryFilter.rent_to_own .MHub-Dropdown-Placeholder::before {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 0.5rem;
  border: 2px solid #00233B;
  background: transparent;
}
li.MHub-Dropdown-Item.bumi::before,
.CategoryFilter.bumi .MHub-Dropdown-Placeholder::before {
  border-color: #3DA0E4;
}
li.MHub-Dropdown-Item.tenanted::before,
.CategoryFilter.tenanted .MHub-Dropdown-Placeholder::before{
  border-color: #6DC3A3;
}
li.MHub-Dropdown-Item.rent_to_own::before,
.CategoryFilter.rent_to_own .MHub-Dropdown-Placeholder::before{
  border-color: #CC397B;
}
