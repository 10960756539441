.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}

.App:not(.hideHeader) {
  padding-top: 3.5rem;
  height: calc(100vh - 3.5rem);
}
