.DetailField {
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  color: #596975;
}

.DetailField.header {
  margin-top: 1rem;
  font-size: 1.1em;
  font-family: 'Montserrat', sans-serif;
}

.DetailField > .label,
.DetailField.header {
  color: #00233B;
  font-weight: bold;
  text-transform: capitalize;
}

.DetailField > .label {
  width: 40%;
}
.DetailField.icon > .label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DetailField > .label > img {
  margin-right: 1rem;
}

.DetailField > .content {
  flex: 1;
}

.DetailField.capital > .content {
  text-transform: capitalize;
}

.DetailField.preview > .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
