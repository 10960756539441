/* status box color */
body .MHub-Dropdown-Item.new::before,
.StatusFilter .MHub-Dropdown-Item.new::before,
.StatusFilter.new .MHub-Dropdown-Placeholder::before,
body .MHub-Dropdown-Item.reserved::before,
.StatusFilter .MHub-Dropdown-Item.reserved::before,
.StatusFilter.reserved .MHub-Dropdown-Placeholder::before,
body .MHub-Dropdown-Item.booked::before,
.StatusFilter .MHub-Dropdown-Item.booked::before,
.StatusFilter.booked .MHub-Dropdown-Placeholder::before,
body .MHub-Dropdown-Item.sold::before,
.StatusFilter .MHub-Dropdown-Item.sold::before,
.StatusFilter.sold .MHub-Dropdown-Placeholder::before,
body .MHub-Dropdown-Item.delisted::before,
.StatusFilter .MHub-Dropdown-Item.delisted::before,
.StatusFilter.delisted .MHub-Dropdown-Placeholder::before {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 0.5rem;
  border: 1px solid #00233B;
  background: transparent;
}
body .MHub-Dropdown-Item.new::before,
.StatusFilter .MHub-Dropdown-Item.new::before,
.StatusFilter.new .MHub-Dropdown-Placeholder::before {
  border-color: #8FD184;
}
body .MHub-Dropdown-Item.reserved::before,
.StatusFilter .MHub-Dropdown-Item.reserved::before,
.StatusFilter.reserved .MHub-Dropdown-Placeholder::before {
  border-color: #FFDC26;
  background: #FFDC26;
}
body .MHub-Dropdown-Item.booked::before,
.StatusFilter .MHub-Dropdown-Item.booked::before,
.StatusFilter.booked .MHub-Dropdown-Placeholder::before {
  border-color: #FFB000;
  background: #FFB000;
}
body .MHub-Dropdown-Item.sold::before,
.StatusFilter .MHub-Dropdown-Item.sold::before,
.StatusFilter.sold .MHub-Dropdown-Placeholder::before {
  border-color: #FF796E;
  background: #FF796E;
}
body .MHub-Dropdown-Item.delisted::before,
.StatusFilter .MHub-Dropdown-Item.delisted::before,
.StatusFilter.delisted .MHub-Dropdown-Placeholder::before {
  border-color: rgb(118,118,118);
  background: rgb(118,118,118);
}
