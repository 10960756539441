/* Fix Headers */
.MHub-RichTextEditor .DraftEditor-editorContainer h1,
.MHub-RichTextEditor .DraftEditor-editorContainer h2,
.MHub-RichTextEditor .DraftEditor-editorContainer h3,
.MHub-RichTextEditor .DraftEditor-editorContainer h4,
.MHub-RichTextEditor .DraftEditor-editorContainer h5,
.MHub-RichTextEditor .DraftEditor-editorContainer h6 {
  font-weight: bold;
}
.MHub-RichTextEditor .DraftEditor-editorContainer h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
.MHub-RichTextEditor .DraftEditor-editorContainer h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.MHub-RichTextEditor .DraftEditor-editorContainer h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.MHub-RichTextEditor .DraftEditor-editorContainer h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.MHub-RichTextEditor .DraftEditor-editorContainer h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.MHub-RichTextEditor .DraftEditor-editorContainer h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

/* Main */
.MHub-RichTextEditor {
  font-family: 'Georgia', serif;
  font-size: 14px;
}

.MHub-RichTextEditor-Editor {
  word-break: break-word;
}

.MHub-RichTextEditor-Editor .DraftEditor-root {
  width: 100%;
}

.MHub-RichTextEditor-Editor .public-DraftEditorPlaceholder-root {
  position: absolute;
}

.MHub-RichTextEditor.focus .public-DraftEditorPlaceholder-inner {
  display: none;
}

.MHub-RichTextEditor .public-DraftEditorPlaceholder-hasFocus {
  display: none;
}

.MHub-RichTextEditor .public-DraftEditorPlaceholder-inner {
  color: #C8C8C8;
  user-select: none;
}

.MHub-RichTextEditor-Editor .public-DraftEditor-content {
  min-height: 80px;
  min-width: 5rem;
  word-break: break-word;
}

.MHub-RichTextEditor-Editor:not(.fluid) .public-DraftEditor-content {
  max-width: 5rem;
}

/* block styles */
.MHub-RichTextEditor-Editor blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

/* text-align-left */
.MHub-RichTextEditor-Editor .text-align-left {
  text-align: left;
}

/* text-align-center */
.MHub-RichTextEditor-Editor .text-align-center {
  text-align: center;
}

/* text-align-right */
.MHub-RichTextEditor-Editor .text-align-right {
  text-align: right;
}

/* text-align-justify */
.MHub-RichTextEditor-Editor .text-align-justify {
  text-align: justify;
}

.MHub-RichTextEditor-Editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
}

.MHub-RichTextEditor-Controls-Wrapper {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  user-select: none;

  background: rgba(119, 143, 155, 0.8);
  padding: 0.5rem;
}

.MHub-RichTextEditor-Controls {
  border-radius: 0;
  color: rgba(51, 51, 51, 1);
}
