.SVGViewer {
  position: relative;
  overflow: hidden;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
}


/* // before ios 10 */
@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
  .SVGViewer:before {
    display: block;
    content: "";
    padding-top: 100%;
  }
}
/* // ios 10 */
@supports (-webkit-overflow-scrolling:touch) and (color:#ffff) {
  .SVGViewer:before {
    display: block;
    content: "";
    padding-top: 100%;
  }
}

.SVGViewer:not(.loaded) svg {
  opacity: 0;
}

.SVGViewer > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* date */
.SVGViewer > .time {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  user-select: none;
  pointer-events: none;
  color: #596975;;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  font-size: 0.75em;
}

/* button */
.SVGViewer .buttons {
  user-select: none;
  position: absolute;
  right: 1rem;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
}
.SVGViewer .buttons:hover {
  opacity: 1;
}
.SVGViewer .buttons button {
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border: 1px solid #DBDDDE;
  border-radius: 0.285rem;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  background: white;
  outline: none;
  overflow: hidden;
  color: #596975;
  box-shadow: 0 0.15em 0.5em rgba(0, 0, 0, 0.1);
}
.SVGViewer .buttons button * {
  cursor: pointer;
}
.SVGViewer .buttons button span svg {
  width: 75%;
  fill: #596975;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.SVGViewer .buttons button.active {
  background: #49B3B1;
}
.SVGViewer .buttons button.active span svg {
  fill: white;
}
.SVGViewer .buttons button:active {
  border: 2px solid #DBDDDE;
}
.SVGViewer .buttons button:active span svg {
  padding: calc(0.25rem - 1px);
}
.SVGViewer .buttons button:hover,
.SVGViewer .buttons button:active:not(.active) {
  color: #49B3B1;
  border-color: #49B3B1;
}
.SVGViewer .buttons button:active:not(.active) {
  border-width: 2px;
}
.SVGViewer .buttons button:hover,
.SVGViewer .buttons button:active:not(.active) span svg {
  fill: #49B3B1;
}
.SVGViewer .buttons button:not(:first-child) {
  margin-top: 0.5em;
}
.SVGViewer .buttons button.hidden {
  height: 0;
  pointer-events: none;
  opacity: 0;
}


/* svg */
.SVGViewer svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: move;
  transition: opacity 0.3s;
  touch-action: none;
}
.SVGViewer svg #mhub-site-plan,
.SVGViewer svg #mhub-units {
  opacity: 1 !important;
}
.SVGViewer svg #mhub-site-plan.animated {
  transition: all 0.5s;
}
.SVGViewer svg #mhub-site-plan > *:not(#mhub-units) {
  pointer-events: none !important;
  user-select: none;
}
.SVGViewer svg #mhub-units > g {
  opacity: 1 !important;
  cursor: default;
}
.SVGViewer svg #mhub-units > g > :not(.status) {
  transition: all 0.2s;
  opacity: 1 !important;
  cursor: default;
  fill: transparent !important;
  stroke: none !important;
  stroke-width: 0 !important;
}
.SVGViewer svg #mhub-units > g[id^="mhub-id:"].selectable {
  cursor: pointer;
}
.SVGViewer svg #mhub-units > g[id^="mhub-id:"].selectable > :not(.status) {
  opacity: 1 !important;
  cursor: pointer;
  fill: transparent !important;
}
.SVGViewer svg #mhub-units > g > .status {
  fill: white;
  font-size: 0.5em;
  font-weight: bold;
  stroke: black;
  stroke-width: 2.5;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 3;
  paint-order: stroke;
  user-select: none;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
}
.SVGViewer svg #mhub-units > g > .status.high-rise {
  font-size: 1.2em;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE 10, 11 fix svg text paint-order */
  .SVGViewer svg #mhub-units > g > .status {
    font-weight: 900;
    stroke-width: 0.5;
  }
}

.SVGViewer svg g#mhub-units > g[id^="mhub-id:"].selectable:hover:not(.selected) > :not(.status) {
  stroke: black !important;
  stroke-width: 2 !important;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.SVGViewer svg g#mhub-units > g[id^="mhub-id:"].selected > :not(.status) {
  stroke: #49B3B1 !important;
  stroke-width: 2 !important;
  stroke-linecap: round;
  stroke-linejoin: round;
}

/* === TEXT OVERLAY === */
/* filter by status */
.SVGViewer:not(.color).new svg #mhub-units > g.selectable.new > :not(.status),
.SVGViewer:not(.color).reserved svg #mhub-units > g.selectable.reserved > :not(.status),
.SVGViewer:not(.color).booked svg #mhub-units > g.selectable.booked > :not(.status),
.SVGViewer:not(.color).sold svg #mhub-units > g.selectable.sold > :not(.status),
.SVGViewer:not(.color).delisted svg #mhub-units > g.selectable.delisted > :not(.status),
.SVGViewer:not(.color).delisted svg #mhub-units > g:not(.selectable) > :not(.status),
/* filtered by layout & status*/
.SVGViewer:not(.color).filtered:not(.new):not(.reserved):not(.booked):not(.sold):not(.delisted) svg #mhub-units > g.selectable.filtered > :not(.status),
.SVGViewer:not(.color).filtered.new svg #mhub-units > g.selectable.filtered.new > :not(.status),
.SVGViewer:not(.color).filtered.reserved svg #mhub-units > g.selectable.filtered.reserved > :not(.status),
.SVGViewer:not(.color).filtered.booked svg #mhub-units > g.selectable.filtered.booked > :not(.status),
.SVGViewer:not(.color).filtered.sold svg #mhub-units > g.selectable.filtered.sold > :not(.status),
.SVGViewer:not(.color).filtered.delisted svg #mhub-units > g.selectable.filtered.delisted > :not(.status),
.SVGViewer:not(.color).filtered.delisted svg #mhub-units > g.filtered:not(.selectable) > :not(.status) {
  fill: transparent !important;
}
.SVGViewer:not(.color).new svg #mhub-units > g:not(.new) > :not(.status),
.SVGViewer:not(.color).reserved svg #mhub-units > g:not(.reserved) > :not(.status),
.SVGViewer:not(.color).booked svg #mhub-units > g:not(.booked) > :not(.status),
.SVGViewer:not(.color).sold svg #mhub-units > g:not(.sold) > :not(.status),
.SVGViewer:not(.color).delisted svg #mhub-units > g.selectable:not(.delisted) > :not(.status),
/* filtered by layout & status*/
.SVGViewer:not(.color).filtered:not(.new):not(.reserved):not(.booked):not(.sold):not(.delisted) svg #mhub-units > g:not(.filtered) > :not(.status),
.SVGViewer:not(.color).filtered.new svg #mhub-units > g:not(.filtered) > :not(.status),
.SVGViewer:not(.color).filtered.reserved svg #mhub-units > g:not(.filtered) > :not(.status),
.SVGViewer:not(.color).filtered.booked svg #mhub-units > g:not(.filtered) > :not(.status),
.SVGViewer:not(.color).filtered.sold svg #mhub-units > g:not(.filtered) > :not(.status),
.SVGViewer:not(.color).filtered.delisted svg #mhub-units > g:not(.filtered) > :not(.status) {
  fill: rgba(0, 0, 0, 0.5) !important;
}
/* status text filter by status */
.SVGViewer.new svg #mhub-units > g.selectable.new > .status,
.SVGViewer.reserved svg #mhub-units > g.selectable.reserved > .status,
.SVGViewer.booked svg #mhub-units > g.selectable.booked > .status,
.SVGViewer.sold svg #mhub-units > g.selectable.sold > .status,
.SVGViewer.delisted svg #mhub-units > g.selectable.delisted > .status,
.SVGViewer.delisted svg #mhub-units > g:not(.selectable) > .status,
/* status text filtered by layout & status */
.SVGViewer.filtered:not(.new):not(.reserved):not(.booked):not(.sold):not(.delisted) svg #mhub-units > g.selectable.filtered > .status,
.SVGViewer.filtered.new svg #mhub-units > g.selectable.filtered.new > .status,
.SVGViewer.filtered.reserved svg #mhub-units > g.selectable.filtered.reserved > .status,
.SVGViewer.filtered.booked svg #mhub-units > g.selectable.filtered.booked > .status,
.SVGViewer.filtered.sold svg #mhub-units > g.selectable.filtered.sold > .status,
.SVGViewer.filtered.delisted svg #mhub-units > g.selectable.filtered.delisted > .status,
.SVGViewer.filtered.delisted svg #mhub-units > g.filtered:not(.selectable) > .status {
  opacity: 1;
}
.SVGViewer.new svg #mhub-units > g:not(.new) > .status,
.SVGViewer.reserved svg #mhub-units > g:not(.reserved) > .status,
.SVGViewer.booked svg #mhub-units > g:not(.booked) > .status,
.SVGViewer.sold svg #mhub-units > g:not(.sold) > .status,
.SVGViewer.delisted svg #mhub-units > g.selectable:not(.delisted) > .status,
/* status text filtered by layout & status */
.SVGViewer.filtered:not(.new):not(.reserved):not(.booked):not(.sold):not(.delisted) svg #mhub-units > g:not(.filtered) > .status,
.SVGViewer.filtered.new svg #mhub-units > g:not(.filtered) > .status,
.SVGViewer.filtered.reserved svg #mhub-units > g:not(.filtered) > .status,
.SVGViewer.filtered.booked svg #mhub-units > g:not(.filtered) > .status,
.SVGViewer.filtered.sold svg #mhub-units > g:not(.filtered) > .status,
.SVGViewer.filtered.delisted svg #mhub-units > g:not(.filtered) > .status {
  opacity: 0.3;
}

/* === COLOR OVERLAY === */
.SVGViewer.color svg #mhub-units > g.selectable.new > :not(.status) {
  fill: transparent !important;
}
.SVGViewer.color svg #mhub-units > g.selectable.reserved > :not(.status) {
  fill: rgba(255, 239, 0, 0.5)!important;
}
.SVGViewer.color svg #mhub-units > g.selectable.booked > :not(.status) {
  fill: rgba(255, 135, 0, 0.5) !important;
}
.SVGViewer.color svg #mhub-units > g.selectable.sold > :not(.status),
.SVGViewer.color svg #mhub-units > g.selectable.unavailable > :not(.status) {
  fill: rgba(255, 0, 0, 0.5) !important;
}
.SVGViewer.color svg #mhub-units > g.selectable.delisted > :not(.status),
.SVGViewer.color svg #mhub-units > g:not(.selectable) > :not(.status) {
  fill: rgba(199, 199, 199, 0.75) !important;
}
/* filtered by status */
.SVGViewer.color.color-filtered:not(.new) svg #mhub-units > g.new > :not(.status) {
  fill: rgba(0, 0, 0, 0.5) !important;
}
.SVGViewer.color.color-filtered:not(.reserved) svg #mhub-units > g.reserved > :not(.status) {
  fill: rgba(64, 60, 0, 0.75) !important;
}
.SVGViewer.color.color-filtered:not(.booked) svg #mhub-units > g.booked > :not(.status) {
  fill: rgba(64, 34, 0, 0.75) !important;
}
.SVGViewer.color.color-filtered:not(.sold) svg #mhub-units > g.sold > :not(.status) {
  fill: rgba(64, 0, 0, 0.75) !important;
}
.SVGViewer.color.color-filtered:not(.delisted) svg #mhub-units > g.delisted > :not(.status),
.SVGViewer.color.color-filtered:not(.delisted) svg #mhub-units > g:not(.selectable) > :not(.status) {
  fill: rgba(75, 75, 75, 0.875) !important;
}

/* for bumi and tenanted filter */
.SVGViewer svg #mhub-units > g[id^="mhub-id:"].selectable.bumi-filter > :not(.status){
  stroke: #3DA0E4 !important;
  stroke-width: 2 !important;
}
.SVGViewer svg g#mhub-units > g[id^="mhub-id:"].selectable.bumi-filter:hover:not(.selected) > :not(.status) {
  stroke: #3DA0E4 !important;
  stroke-width: 2.5 !important;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.SVGViewer svg #mhub-units > g[id^="mhub-id:"].selectable.tenanted-filter > :not(.status){
  stroke: #6DC3A3 !important;
  stroke-width: 2 !important;
}
.SVGViewer svg g#mhub-units > g[id^="mhub-id:"].selectable.tenanted-filter:hover:not(.selected) > :not(.status) {
  stroke: #6DC3A3 !important;
  stroke-width: 2.5 !important;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.SVGViewer svg #mhub-units > g[id^="mhub-id:"].selectable.rentToOwn-filter > :not(.status){
  stroke: #CC397B !important;
  stroke-width: 2 !important;
}
.SVGViewer svg g#mhub-units > g[id^="mhub-id:"].selectable.rentToOwn-filter:hover:not(.selected) > :not(.status) {
  stroke: #CC397B !important;
  stroke-width: 2.5 !important;
  stroke-linecap: round;
  stroke-linejoin: round;
}

/* filtered by layout */
.SVGViewer.color.filtered svg #mhub-units > g.selectable.new:not(.filtered) > :not(.status) {
  fill: rgba(0, 0, 0, 0.5) !important;
}
.SVGViewer.color.filtered svg #mhub-units > g.selectable.reserved:not(.filtered) > :not(.status) {
  fill: rgba(64, 60, 0, 0.75) !important;
}
.SVGViewer.color.filtered svg #mhub-units > g.selectable.booked:not(.filtered) > :not(.status) {
  fill: rgba(64, 34, 0, 0.75) !important;
}
.SVGViewer.color.filtered svg #mhub-units > g.selectable.sold:not(.filtered) > :not(.status) {
  fill: rgba(64, 0, 0, 0.75) !important;
}
.SVGViewer.color.filtered svg #mhub-units > g.selectable.delisted:not(.filtered) > :not(.status),
.SVGViewer.color.filtered svg #mhub-units > g:not(.selectable):not(.filtered) > :not(.status) {
  fill: rgba(75, 75, 75, 0.875) !important;
}

.SVGViewer.basic svg #mhub-units > g:not(.selectable) > :not(.status) {
  fill: rgba(0, 0, 0, 0.5) !important;
}

/* label guide for bumi and tenant */
.bumiLabel {
  position: absolute;
  top: 2em;
  right: 2em;
}
.tenantedLabel {
  position: absolute;
  top: 5em;
  right: 2em;
}
.labelGuide >.box{
  width: 2em;
  height: 1em;
  border-width: 2px;
  border-style: solid;
  background-color: none;
  display: inline-block;
  vertical-align: middle;
}
.labelGuide > p{
  margin-left: 1em;
  display: inline-block;
  vertical-align: middle;
}


